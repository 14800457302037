/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

@media screen and (max-width: 550px) {
  ._2-col-layout {
    display: block!important;
  }
  .col {
    width: unset;
  }
}

.preview-image {
  max-width: 100%;
  max-height: 400px;
  padding-bottom: 10px;
}

.col {
  width: 50%;
}

.container {
  text-align: center;
  max-width: 780px!important;
}

code {
}

.container * {
  margin-left: auto;
  margin-right: auto;
}